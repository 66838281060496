import { THEME_TYPE } from "../constants/theme";

const Theme = {
    [THEME_TYPE.LIGHT]: {
        backgroundBody: "#eee",
        bodyColor: "#404041",
        backgroundTopBar: "#FFF",
        SchdulePopup: "#d5d5d5",
        backgroundWhite: "#FFF",
        headingColor: "#4B4D4D",
        text: "#rgba(0, 0, 0, 0.87)",
        greyText: "#404041",
        textWhite: "#000",
        divider: "rgba(0, 0, 0, 0.12)",
        shadow: "0 2px 4px 0 hsla(0,0%,87%,.5)",
        colShadow: "0px 2px 4px 0px rgba(223, 223, 223, 0.50)",
        shadowBox: "0px 2px 4px 0px rgba(223, 223, 223, 0.50)",
        stickyShadow: "0px 2px 4px 0px rgb(69 69 69 / 50%)",
        borderColortheme: "0.25rem solid #eeeeee",
        greenb: "#28A745",
        t: "rgba(0, 0, 0, 0.87)",
        circleColor: "#63BC62",
        circleBG: "#FFF",
        fw: "#458FFF",
        // dlcolor:"#7c7c7c",
        dlcolor: "#000",
        arr: "#6f6f6f",
        bgdiabled: "#e7e7e7",
        bgActiveTab: "#e2f3eb",
        colorActiveTab: "#1b711e",
        modalBg: "#eee",
        gr: "#eee",
        border: "#D9D9D9",
        BlueBorder: "#4e7e9a",
        lightBorder: "#dee2e6",
        // filteri: "invert(1)",
        opecity: "5%",
        greyBG: "#c5c5c5",
       BRC: "var(--bs-table-border-color)",
       filter1: "brightness(0.3)",
       BGTable: "#f5f5f5",
       switchBG: "#d1d1d1",
       borderColor: "#828282"
      
    },
    [THEME_TYPE.DARK]: {
        backgroundBody: "#000",
        bodyColor: "#FFF",
        backgroundTopBar: "#191C24 !important",
        SchdulePopup: "#191C24 !important",
        backgroundWhite: "#FFF",
        headingColor: "#FFF",
        text: "#rgba(0, 0, 0, 0.87)",
        greyText: "#FFF !important",
        textWhite: "#FFF",
        divider: "rgba(0, 0, 0, 0.12)",
        shadow: "0px 2px 4px 0px rgb(28 28 28 / 50%)",
        whiteClor: "#FFF",
        borderColortheme: "0.25rem solid #000",
        filter: "brightness(10)",
        colShadow: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
        shadowBox: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
        stickyShadow: "0px 2px 4px 3px #191C24",
        greenb: "#000",
        t: "#FFF",
        circleColor: "#FFF",
        circleBG: "#191c23",
        dlcolor: "#FFF",
        // fw:"#494949",
        arr: "#FFF",
        bgdiabled: "#303030",
        bgActiveTab: "#013800",
        colorActiveTab: "#FFF",
        modalBg: "#272727",

        // ----------------Only in Dark Mode-------------------
        trackBg: "#3B3B3B",
        borderColor: "#7f7f7f",
        bgdark: "#1f1f1f !important",
        filteri: "invert(1)",
        border: "#7f7f7f",
        BlueBorder: "#7f7f7f",
        bd2: "#000000",
        borderTop: "1px solid rgb(221 195 195 / 18%)",
        disabled: "#494949",
        btnClose: "#000 !important",
        datepickerbg: "transparent",
        fw: "400",
        lightBorder: "#7f7f7f",
        opecity: "48%",
        BRC: "#7f7f7f",
        SelectBorder: "#ced4da",
        filter1: "brightness(0.7)",
        BGTable: "#232529",
        switchBG: "#5e5e5e",
        borderColor: "#828282"
    },
};

const ThemeStyle = (theme) => `
input.border-color{     border-color:  ${Theme[theme].textWhite} !important;}
.w-100-input input{     color:  ${Theme[theme].bodyColor} !important;}
.timeline .nav-pills li button {  color: ${Theme[theme].bodyColor} ; }
.layer{  background:  ${Theme[theme].switchBG} !important;}
select.color-change{color:  ${Theme[theme].textWhite} !important;}
body, .bg-theme option {background-color: ${Theme[theme].backgroundBody} !important;}
body {color: ${Theme[theme].bodyColor};}
.dropdown-header, .chat-g input{color: ${Theme[theme].bodyColor};}
.bg-white {background-color: ${Theme[theme].backgroundTopBar};}
.text-color {color: ${Theme[theme].text};}
.box {border: 1px solid ${Theme[theme].divider}}
.top-bar {background-color: ${Theme[theme].backgroundTopBar}}
.top-bar {box-shadow: ${Theme[theme].shadow}}
.sidebar {background-color: ${Theme[theme].backgroundTopBar}}
.h-half{background-color: ${Theme[theme].backgroundTopBar}}
.sidebar ul li a {color: ${Theme[theme].greyText}}
.sidebar {box-shadow: ${Theme[theme].shadow}}
.color-black {color: ${Theme[theme].textWhite}}
.widget-tabs h3{color: ${Theme[theme].textWhite}}
.widget-tabs p{color: ${Theme[theme].greyText}}
.sp1{color: ${Theme[theme].textWhite}}
.sp2{color: ${Theme[theme].greyText}}
table{color: ${Theme[theme].greyText}}
.box-heading{color: ${Theme[theme].headingColor}}
.ind-table th{color: ${Theme[theme].headingColor}}
.dropdown-toggle:empty::after{color: ${Theme[theme].whiteClor}}
.white-bg{background-color: ${Theme[theme].backgroundTopBar}}
.white-bg{color: ${Theme[theme].greyText}}
.border-bottom-5{border-bottom: ${Theme[theme].borderColortheme}}
.border-top-5{border-top: ${Theme[theme].borderColortheme}}
table thead tr th .ms-1 img {filter: ${Theme[theme].filter}}
table thead tr th .ms-2 img {filter: ${Theme[theme].filter}}
.commenticon {filter: ${Theme[theme].filter1}}
.table-hover>tbody>tr:hover>* {color:${Theme[theme].whiteClor}}
.scroll-table tbody::-webkit-scrollbar-thumb { background: #848689;}
.scroll-table tbody::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.scrollbar::-webkit-scrollbar-thumb { background: #848689;}
.scrollbar::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.overflow-auto::-webkit-scrollbar-thumb { background: #848689;}
.overflow-auto::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}

.css-1n6sfyn-MenuList::-webkit-scrollbar-thumb { background: #848689;}
.css-1n6sfyn-MenuList::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.css-qr46ko::-webkit-scrollbar-thumb { background: #848689;}
.css-qr46ko::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}


.width-in::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb { border-radious:7px; background: #848689;}
.width-in::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.overlfow-auto::-webkit-scrollbar, .chat-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

.table-responsive::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
.overlfow-auto::-webkit-scrollbar-thumb, .chat-area::-webkit-scrollbar-thumb { background: #848689; border-radius: 30px;}
.overlfow-auto::-webkit-scrollbar-track, .chat-area::-webkit-scrollbar-track { border-radius: 30px;background-color: ${Theme[theme].trackBg}}
.table-responsive::-webkit-scrollbar-thumb { background: #848689;}
.table-responsive::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.scroll-height-600::-webkit-scrollbar-thumb { background: #848689;}
.scroll-height-600::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.sidenav::-webkit-scrollbar-thumb { background: #848689;}
.sidenav::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}

tbody, td, tfoot, th, thead, tr {border-color:${Theme[theme].borderColor}}
.tb2 tbody tr.bt {border-top: 1px solid ${Theme[theme].borderColor} !important}
.form-select, .col .form-control{color: ${Theme[theme].bodyColor} !important;}
.css-1dimb5e-singleValue{color: ${Theme[theme].bodyColor} !important;}
.modal-content{background-color: ${Theme[theme].backgroundTopBar};}
.schdulepopbg .modal-content{background-color: ${Theme[theme].SchdulePopup};}
.btn-close {filter: ${Theme[theme].filteri}}
.form-select:disabled { background-color: ${Theme[theme].backgroundTopBar} ;  border: 1px solid ${Theme[theme].border} !important;}
.searchlistwrap {   border: 1px solid ${Theme[theme].backgroundBody};  background: ${Theme[theme].backgroundTopBar};}
.searchlistwrap ul li:hover {background-color: ${Theme[theme].backgroundBody};}
.css-1nmdiq5-menu{background-color: ${Theme[theme].backgroundTopBar};}
.css-1nmdiq5-menu li:hover{background-color: ${Theme[theme].backgroundTopBar};}
.col-bg{background-color: ${Theme[theme].backgroundTopBar};
box-shadow:${Theme[theme].colShadow}}
.search-box{box-shadow:${Theme[theme].colShadow}}
.shadow-box{box-shadow: ${Theme[theme].shadowBox};}
.bd-2{border-top:7px solid  ${Theme[theme].bd2};}
.green-b { color:${Theme[theme].bodyColor}; border-color: ${Theme[theme].bodyColor}; background: ${Theme[theme].bd2};}
.dropdown-menu.show{background-color: ${Theme[theme].backgroundTopBar};}
.dropdown-item {color: ${Theme[theme].bodyColor};}
.dropdown-divider{border-top:${Theme[theme].borderTop}}
.t {color: ${Theme[theme].t};}
th.coll{color: ${Theme[theme].headingColor}}
.transparent-btn{color: ${Theme[theme].t};}
.button-disable:disabled{ background-color: ${Theme[theme].bgdark} ; border: 1px solid ${Theme[theme].border} !important;}
.transparent-btn{    border: 1px solid ${Theme[theme].border};}
.spacing-table td {border-color: ${Theme[theme].border};}
.spacing-table.realtime-table td {border-color: ${Theme[theme].BlueBorder};}
.button-shadow:hover{border-color: ${Theme[theme].border};}
span.circle-i{ color: ${Theme[theme].circleColor}; border: 1px solid ${Theme[theme].circleColor}; background: ${Theme[theme].circleBG};}
.form-control{background: ${Theme[theme].circleBG}; color:  ${Theme[theme].textWhite} !important;}
.form-control[disabled]{background: ${Theme[theme].disabled}}
.fw{background:${Theme[theme].fw}}
.btn-close {color: ${Theme[theme].btnClose};}
.form-control:focus {  background-color: inherit !important;}
.react-datepicker-wrapper input{background: ${Theme[theme].datepickerbg};color: ${Theme[theme].bodyColor};}
.table th{color: ${Theme[theme].textWhite}}
.ft-table-v.table td{color: ${Theme[theme].dlcolor} !important;}
.white-text-dark-mode, .modal-title, .form-label{color: ${Theme[theme].headingColor};}
#mySidenav{background-color: ${Theme[theme].backgroundBody};box-shadow:${Theme[theme].stickyShadow};}
.notify-tabs{background-color: ${Theme[theme].backgroundTopBar};box-shadow:${Theme[theme].colShadow};}
.pdf{filter: ${Theme[theme].filteri};}
.apexcharts-tooltip { color: #000 !important;}
.ww1{color: ${Theme[theme].textWhite}; font-family: var(--cf-fp);}
.searchlistwrap .searchfield{background-color: ${Theme[theme].backgroundBody};}
.searchlistwrap .searchfield .searchicon img{filter: contrast(0.1);}
.css-1xc3v61-indicatorContainer svg path {fill: ${Theme[theme].arr};}
.dropdown-item.active, .dropdown-item:active { background: ${Theme[theme].backgroundBody};; color:  ${Theme[theme].bodyColor};}
.nav-pills .v-tab{ color:  ${Theme[theme].bodyColor};}
.css-166bipr-Input, .css-1cfo1cf{color: ${Theme[theme].bodyColor} !important;}
.dropdown-item:focus, .dropdown-item:hover {color:   ${Theme[theme].bodyColor};background-color: ${Theme[theme].backgroundBody};}
.profile-form .form-control[disabled] { cursor: not-allowed !important;background-color: ${Theme[theme].bgdiabled} !important;}
.nav-pills .v-tab.active, .nav-pills .show>.v-tab { color:  ${Theme[theme].colorActiveTab}; background-color: ${Theme[theme].bgActiveTab};}
.dropdownwithsearch .input-group .form-control::placeholder{color:  ${Theme[theme].bodyColor} !important;}
.missed-meal-break .modal-content { background-color: ${Theme[theme].modalBg} !important;}
.border-bottom-5.gr{ border-color: ${Theme[theme].gr} !important;}
.border-top-5.gr{ border-color: ${Theme[theme].gr} !important;}
.ft-table-v .center-border {
    border-left: 1px solid ${Theme[theme].lightBorder} !important;
    border-right: 1px solid ${Theme[theme].lightBorder} !important;
}
.table.ft-table-v th{color: ${Theme[theme].dlcolor}}
.ft-title{color: ${Theme[theme].dlcolor}}
.table {--bs-table-hover-bg: rgb(0 0 0 / ${Theme[theme].opecity});}
.border-less #react-select-3-placeholder {color: ${Theme[theme].dlcolor}}
.css-1dimb5e-singleValue { color: ${Theme[theme].dlcolor} !important;}
.custom-select-v button, .custom-select-v button{background-color: transparent !important; color:  ${Theme[theme].dlcolor} !important;    border: 0px;}
.custom-select-v button + div {   border: 1px solid ${Theme[theme].backgroundBody}  !important;  background: ${Theme[theme].backgroundTopBar} ;margin: 0 !important;}
.custom-select-v button:hover { background: transparent !important; color:  ${Theme[theme].dlcolor} !important;}

// .custom-select-v .css-1n6sfyn-MenuList>div, 
// .custom-select-v .css-qr46ko>div{background-color: transparent; font-size: 16px;    color: inherit;font-size:14px}
.custom-select-v .css-1n6sfyn-MenuList>div, 
.custom-select-v .css-qr46ko>div{font-size:14px}
.custom-select-v .css-1n6sfyn-MenuList>div:active,
 .custom-select-v .css-1n6sfyn-MenuList>div:hover,
 .custom-select-v .css-qr46ko>div:active,
 .custom-select-v .css-qr46ko>div:hover
 
 { background-color:  ${Theme[theme].backgroundBody} !important; color:  ${Theme[theme].dlcolor} !important;}
 .css-d7l1ni-option, .css-d7l1ni-option{background-color:transparent;}
 .css-d7l1ni-option{background-color: ${Theme[theme].backgroundBody} !important;}
.custom-select-v .css-2o2t8s-control, .css-3f3cyk-control{background-color: ${Theme[theme].backgroundBody} !important;}
.custom-select-v .css-2o2t8s-control inpur, .css-3f3cyk-control input{color: ${Theme[theme].bodyColor} !important;
    font-size: 16px !important;
    font-weight: 400 !important;}
    .custom-select-v .css-1n6sfyn-MenuList>div, .custom-select-v .css-qr46ko>div {
        font-size: 16px;
    }
.overt .css-1nmdiq5-menu div {
        font-size: 14px;
    }
.rt-v .next{background-color: ${Theme[theme].backgroundTopBar}; box-shadow: ${Theme[theme].shadowBox};}
.rt-v .prev{background-color: ${Theme[theme].backgroundTopBar}; box-shadow: ${Theme[theme].shadowBox};}
.search-b input {background-color: ${Theme[theme].backgroundTopBar};
    border: 1px solid ${Theme[theme].border};
    box-shadow: none;
    color:  ${Theme[theme].bodyColor};
    padding: 5px;
}
.bg-grey {
    background:  ${Theme[theme].greyBG}}
  .border-bottom.grb{border-color: ${Theme[theme].BRC} }
  .border-bottom {
    border-bottom: 1px solid ${Theme[theme].BRC} !important;
}
.bg-theme{border: 1px solid #ced4da !important;}
.d-block .form-check-input[type=checkbox] {
    border: 1px solid  ${Theme[theme].textWhite};
}
.realtime-table tr td{background-color: ${Theme[theme].BGTable};
.border-color{ border: 1px solid  ${Theme[theme].textWhite};}

`;

export default ThemeStyle;
