import React, { useEffect, useState, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../../constants/";
import axios from "../../../axios";
import { SocketContext } from "../../../socket";
import { getPreviousAndCurrentShift } from "../../../store/actions/Compensate";

import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";
import SHIFT_FORM from "../../../constants/shiftForm";
import { formatDecimalNumber } from "../../../utils/utils";
import {
  decryptedFunction,
  dataEnCrypt,
  formatDashDate,
} from "../../../utils/helper";

import moment from "moment";

const init = {
  acuity: "",
  wit: "",
  actual_admit: "",
  ant_admit: "",
  charge: "",
  cna: "",
  comment: "",
  submitted_by_text: "",
  cost_center_number: "",
  currentShiftNumber: "",
  one_five: "",
  one_six: "",
  one_four: "",
  id: "",
  nurses: "",
  one_one: "",
  two_one: "",
  orientation: "",
  res_brk_rn: "",
  shift_time: "",
  sitter: "",
  telemetry: "",
  one_three: "",
  shift_date: "",
  total_patients: "",
  total_patients_two: "",
  total_patients_three: "",
  one_two: "",
  unit_sec: "",
  vaso_active_drips: "",
  isolation_patients: "",
  of_isolation: "",
  of_rrs_codes: "",
  of_video_monitors: "",
  techs_cna: "",
  psa_si: "",
  psa_medical: "",
  blt: "",
  other: "",
  soc_worker_ft: "",
  soc_worker_pt: "",
  occ_therapist: "",
  pp_rn: "",
  l_and_d_rn: "",
  nicu_peds_rn: "",
  triage_rn: "",
  observation_patients: "",
  inpatient_boarders: "",
  pt_type1_0_20: "",
  pt_type2_21_28: "",
  pt_type3_29_68: "",
  labor_patients_recovery: "",
  nicu_cpap: "",
  nicu_feeder_growers: "",
  post_op_pp_one_two: "",
  stable_pp_one_three: "",
  of_patients_w_video_monitor_last_six_hours: "",
  of_patients_in_seclusion_last_six_hours: "",
  of_patients_in_restraints_last_six_hours: "",
  of_icu_patients_waiting_in_er: "",
  of_anticipated_transfers_in_next_four_hours: "",
  of_anticipated_transfers_out_next_four_hours: "",
  of_rns_wa_wit_higher_than_shift_standard: "",
  of_pcu_pts_waiting_in_er: "",
  of_c_sections_since_last_time_stamp: "",
  of_inductions_since_last_time_stamp: "",
  of_births_since_last_time_stamp: "",
  of_triage_patients_since_last_time_stamp: "",
  of_pp_hemorrhage_ob_emergency: "",
  of_resuscitations: "",
  of_op_dialysis_patients: "",
  l_and_d_rn_variance: "",
  nicu_variance: "",
  pp_rn_variance: "",
  custom_rn_variance: "",
  lead_tech: "",
  clinical_coordinator: "",
  front_desk: "",
  ivs: "",
  ed_reg: "",
  seven_ave_reg: "",
  rt: "",
  support: "",
  scrub_tech: "",
  rn_preceptor: "",
  rn_board_runner: "",
  scrub_tech_preceptor: "",
  scrub_tech_board_runner: "",
  rnfa_stfa: "",
  rcu_rn: "",
  total_uos: "",
  of_cardiac_procedures: "",
  front_desk_reg: "",
  ivs_encounters_processed: "",
  pts_scheduled: "",
  new_referral: "",
  work_queue_calls: "",
  procedures: "",
  echo: "",
  dobutamine_stress_echo: "",
  stress_echo: "",
  transesopageal_echo: "",
  gi: "",
  ercp: "",
  bronchoscopy: "",
  planned_procedures: "",
  of_cases: "",
  items: "",
  unprocessed_trays: "",
  cases_scheduled_today: "",
  cases_picked: "",
  actual_minutes: "",
  planned_minutes: "",
  iol_pain_local_cases: "",
  mac_cases: "",
  pacu_cases: "",
  cath_lab_cases: "",
  level_1_visits: "",
  level_2_visits: "",
  level_3_visits: "",
  level_4_visits: "",
  level_5_visits: "",
  of_roomed_er_patients: "",
  psych_pts_awaiting_placement: "",
  capped_pts_awaiting_hospitalist_assignment: "",
  admits_awaiting_bed_assignment_boarders: "",
  pts_in_waiting_room: "",
  seven_ave_reg_patient: "",
  ed_reg_patient: "",
  cx_no_shows_w_i_24_hrs: "",
  downtime_hrs: "",
  hrs_non_cath_proc: "",
  of_proc_cx_no_shows_w_i_24_hrs: "",
  hrs_equip_down: "",
  on_call_hrs_paid: "",
  times_called_in: "",
  of_hrs_in_surgery: "",
  active_work_queue: "",
  total_work_queue: "",
  uncontacted_referral_grater_7_days: "",
  surgery_hrs: "",
  fluoro_hrs: "",
  ip_same_day_add_ons: "",
  actual_rn_worked_hrs: "",
  uae_echos: "",
  providers_out: "",
  cancellations_within_24_hrs: "",
  ip_add_ons: "",
  dac_hrs_spent: "",
  no_shows: "",
  cx_w_i_24_hrs: "",
  patients_w_stay_grater_70_min: "",
  crash_carts_set_up_restocked: "",
  instruments_returned_unused: "",
  flashed_items: "",
  procedures_requiring_staff_grater_normal_ortho: "",
  procedures_requiring_grater_normal_plastics: "",
  procedures_requiring_grater_normal_for_ophthamolgy: "",
  procedures_requiring_grater_normal_general_baritics_other: "",
  ip_status_patients_held_over: "",
  of_hours_the_rcu_was_open_last_4_hrs: "",
  one_1_and_1_2_pts_currently_awaiting_placement: "",
  code_3s_stemi_or_strokes_last_4_hours: "",
  of_psa_si_pts: "",
  of_lost_doses: "",
  active_cases_in_the_work_queue: "",
  of_ir_vas_procedures: "",
  in_chair_minutes: "",
  pain_management: "",
  hi_flow_in_us: "",
  of_vents_in_use: "",
  of_bi_paps_in_use: "",
  of_c_paps_in_use_noc_only: "",
  of_loaner_trays_processed: "",
  lab_technologist: "",
  phlebotomist_clinical_services: "",
  education_orientation: "",
  contract_labor_tech: "",
  contract_labor_phlebotomy: "",
  histology: "",
  client_services: "",
  receptionist: "",
  health_plan_coordinator: "",
  medical_assistant: "",
  lead_medical_assistant: "",
  radiologist_technologist: "",
  athletic_trainer: "",
  urgent_cases_added_to_schedule: "",
  unplanned_providers_absent: "",
  no_show_patients_same_day_cancelation: "",
};

function UpdateModal({
  isShow,
  callbackfunc,
  reloaddata,
  data_id,
  getprevAndCurrentShift,
  gridData,
  cost_center_number,
  cost_center_name,
  numbers,
  getPreAndCurr,
  acuityNurse,
  formContent,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const shiftLength = 4;
  const validateCensus = formContent?.validate_census;
  const validateBeds = formContent?.beds;
  const [formdata, setFormdata] = useState(init);
  const userType = JSON.parse(localStorage.getItem("psdata"))?.data?.user_type; //userType 0 for admin
  const socket = useContext(SocketContext);
  const [successMsg, setSuccessmsg] = useState("");
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const detail_data = selector.compensate.departmentdetail;
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [variance, setVariance] = useState({});
  const [totalvariance, setTotalVar] = useState(0);
  const [dataid, setDataid] = useState(null);
  //console.log("sdsdds", contentone);
  const [gridUpdateData, setGridUpdateData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const activedatefilter = selector.compensate?.activedatefilter;
  const [submitedBy, setSubmitedBy] = useState("");
  const [isKeyEmpty, setIsKeyEmpty] = useState(false);
  const [formControlData, setFormControltData] = useState({});
  const [formShiftData, setFormShiftData] = useState([]);
  const [formShiftLabel, setFormShiftLabel] = useState({});
  const previousShiftData =
    selector.compensate.getPreviousShiftData?.directData;
  const [rnTotal, setRnTotal] = useState(0);
  const [rnAvailableData, setRnAvailableData] = useState({});
  const [witRn, setWitRn] = useState(0);
  const [rnVariance, setRnVariance] = useState(0);
  const [bltChecked, setBltChecked] = useState(0);
  const [ldRnVariance, setLdRnVariance] = useState(0);
  const [nicuVariance, setNicuVariance] = useState(0);
  const [ppRnVariance, setppRnVariance] = useState(0);
  const [customRnVariance, setCustomRnVariance] = useState(0);
  const [unitServiceCal, setUnitServiceCal] = useState(0);
  const [procedureOneCal, setProcedureOneCal] = useState(0);
  const [procedureTwoCal, setProcedureTwoCal] = useState(0);
  const [itemsCal, setItemsCal] = useState(0);
  const [minutesCal, setMinutesCal] = useState(0);
  const [clinicVisitCal, setClinicVisitCal] = useState(0);
  const [iolMacPacuCal, setIolMacPacuCal] = useState(0);
  const [ofMinutesCal, setOfMinutesCal] = useState(0);
  const [totalRegCal, setTotalRegCal] = useState(0);
  const [lnCensusGrid, setlnCensusGrid] = useState(0);
  const [nicuCensusGrid, setNicuCensusGrid] = useState(0);
  const [nicuCensusData, setNicuCensusData] = useState({});
  const [ppCensusGrid, setPpCensusGrid] = useState(0);
  const [ppCensusData, setPpCensusData] = useState({});

  const [uosPatientData, setUosPatientData] = useState({});
  const [totalUosPatient, setTotalUosPatient] = useState(0);
  const [uosStaffingData, setUosStaffingData] = useState({});
  const [totalUosStaffing, setTotalUosStaffing] = useState(0);
  const [plannedHpous, setPlannedHpous] = useState(0);
  // Staffing key Inputs
  const setvarianceValue = (event) => {
    //console.log(variance, "variance");

    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_charge
            ? gridUpdateData?.required_charge
            : 0),
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_charge
            ? gridUpdateData?.required_charge
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0),
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0));
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.orientation ? gridUpdateData?.orientation : 0),
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.orientation ? gridUpdateData?.orientation : 0));
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_nurses
            ? gridUpdateData?.required_nurses
            : 0),
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_nurses
            ? gridUpdateData?.required_nurses
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_res_brk_rn
            ? gridUpdateData?.required_res_brk_rn
            : 0),
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_res_brk_rn
            ? gridUpdateData?.required_res_brk_rn
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0),
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0));
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.sitter ? gridUpdateData?.sitter : 0),
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.sitter ? gridUpdateData?.sitter : 0));
      setTotalVar(sum);
    } else if (event.target.name == "techs_cna") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.techs_cna ? gridUpdateData?.techs_cna : 0),
      });
      const { techs_cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.techs_cna ? gridUpdateData?.techs_cna : 0));
      setTotalVar(sum);
    } else if (event.target.name == "psa_si") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.psa_si ? gridUpdateData?.psa_si : 0),
      });
      const { psa_si, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.psa_si ? gridUpdateData?.psa_si : 0));
      setTotalVar(sum);
    } else if (event.target.name == "psa_medical") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.psa_medical ? gridUpdateData?.psa_medical : 0),
      });
      const { psa_medical, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.psa_medical ? gridUpdateData?.psa_medical : 0));
      setTotalVar(sum);
    } else if (event.target.name == "blt") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - (gridUpdateData?.blt ? gridUpdateData?.blt : 0),
      });
      const { blt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - (gridUpdateData?.blt ? gridUpdateData?.blt : 0));
      setTotalVar(sum);
    } else if (event.target.name == "other") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.other ? gridUpdateData?.other : 0),
      });
      const { other, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.other ? gridUpdateData?.other : 0));
      setTotalVar(sum);
    } else if (event.target.name == "soc_worker_ft") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.soc_worker_ft ? gridUpdateData?.soc_worker_ft : 0),
      });
      const { soc_worker_ft, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.soc_worker_ft ? gridUpdateData?.soc_worker_ft : 0));
      setTotalVar(sum);
    } else if (event.target.name == "soc_worker_pt") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.soc_worker_pt ? gridUpdateData?.soc_worker_pt : 0),
      });
      const { soc_worker_pt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.soc_worker_pt ? gridUpdateData?.soc_worker_pt : 0));
      setTotalVar(sum);
    } else if (event.target.name == "occ_therapist") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.occ_therapist ? gridUpdateData?.occ_therapist : 0),
      });
      const { occ_therapist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.occ_therapist ? gridUpdateData?.occ_therapist : 0));
      setTotalVar(sum);
    } else if (event.target.name == "pp_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.pp_rn ? gridUpdateData?.pp_rn : 0),
      });
      const { pp_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.pp_rn ? gridUpdateData?.pp_rn : 0));
      setTotalVar(sum);
    } else if (event.target.name == "l_and_d_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.l_and_d_rn ? gridUpdateData?.l_and_d_rn : 0),
      });
      const { l_and_d_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.l_and_d_rn ? gridUpdateData?.l_and_d_rn : 0));
      setTotalVar(sum);
    } else if (event.target.name == "nicu_peds_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.nicu_peds_rn ? gridUpdateData?.nicu_peds_rn : 0),
      });
      const { nicu_peds_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.nicu_peds_rn ? gridUpdateData?.nicu_peds_rn : 0));
      setTotalVar(sum);
    } else if (event.target.name == "triage_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.triage_rn ? gridUpdateData?.triage_rn : 0),
      });
      const { triage_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.triage_rn ? gridUpdateData?.triage_rn : 0));
      setTotalVar(sum);
    } else if (event.target.name == "lead_tech") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.lead_tech ? gridUpdateData?.lead_tech : 0),
      });
      const { lead_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.lead_tech ? gridUpdateData?.lead_tech : 0));
      setTotalVar(sum);
    } else if (event.target.name == "clinical_coordinator") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.clinical_coordinator
            ? gridUpdateData?.clinical_coordinator
            : 0),
      });
      const { clinical_coordinator, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.clinical_coordinator
            ? gridUpdateData?.clinical_coordinator
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "front_desk") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.front_desk ? gridUpdateData?.front_desk : 0),
      });
      const { front_desk, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.front_desk ? gridUpdateData?.front_desk : 0));
      setTotalVar(sum);
    } else if (event.target.name == "ivs") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - (gridUpdateData?.ivs ? gridUpdateData?.ivs : 0),
      });
      const { ivs, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - (gridUpdateData?.ivs ? gridUpdateData?.ivs : 0));
      setTotalVar(sum);
    } else if (event.target.name == "ed_reg") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.ed_reg ? gridUpdateData?.ed_reg : 0),
      });
      const { ed_reg, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.ed_reg ? gridUpdateData?.ed_reg : 0));
      setTotalVar(sum);
    } else if (event.target.name == "seven_ave_reg") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.seven_ave_reg ? gridUpdateData?.seven_ave_reg : 0),
      });
      const { seven_ave_reg, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.seven_ave_reg ? gridUpdateData?.seven_ave_reg : 0));
      setTotalVar(sum);
    } else if (event.target.name == "rt") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - (gridUpdateData?.rt ? gridUpdateData?.rt : 0),
      });
      const { rt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - (gridUpdateData?.rt ? gridUpdateData?.rt : 0));
      setTotalVar(sum);
    } else if (event.target.name == "support") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.support ? gridUpdateData?.support : 0),
      });
      const { support, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.support ? gridUpdateData?.support : 0));
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.scrub_tech ? gridUpdateData?.scrub_tech : 0),
      });
      const { scrub_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.scrub_tech ? gridUpdateData?.scrub_tech : 0));
      setTotalVar(sum);
    } else if (event.target.name == "rn_preceptor") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.rn_preceptor ? gridUpdateData?.rn_preceptor : 0),
      });
      const { rn_preceptor, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.rn_preceptor ? gridUpdateData?.rn_preceptor : 0));
      setTotalVar(sum);
    } else if (event.target.name == "rn_board_runner") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.rn_board_runner
            ? gridUpdateData?.rn_board_runner
            : 0),
      });
      const { rn_board_runner, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.rn_board_runner
            ? gridUpdateData?.rn_board_runner
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech_preceptor") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.scrub_tech_preceptor
            ? gridUpdateData?.scrub_tech_preceptor
            : 0),
      });
      const { scrub_tech_preceptor, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.scrub_tech_preceptor
            ? gridUpdateData?.scrub_tech_preceptor
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech_board_runner") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.scrub_tech_board_runner
            ? gridUpdateData?.scrub_tech_board_runner
            : 0),
      });
      const { scrub_tech_board_runner, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.scrub_tech_board_runner
            ? gridUpdateData?.scrub_tech_board_runner
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "rnfa_stfa") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.rnfa_stfa ? gridUpdateData?.rnfa_stfa : 0),
      });
      const { rnfa_stfa, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.rnfa_stfa ? gridUpdateData?.rnfa_stfa : 0));
      setTotalVar(sum);
    } else if (event.target.name == "rcu_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.rcu_rn ? gridUpdateData?.rcu_rn : 0),
      });
      const { rcu_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.rcu_rn ? gridUpdateData?.rcu_rn : 0));
      setTotalVar(sum);
    } else if (event.target.name == "lab_technologist") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.lab_technologist
            ? gridUpdateData?.lab_technologist
            : 0),
      });
      const { lab_technologist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.lab_technologist
            ? gridUpdateData?.lab_technologist
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "phlebotomist_clinical_services") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.phlebotomist_clinical_services
            ? gridUpdateData?.phlebotomist_clinical_services
            : 0),
      });
      const { phlebotomist_clinical_services, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.phlebotomist_clinical_services
            ? gridUpdateData?.phlebotomist_clinical_services
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "education_orientation") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.education_orientation
            ? gridUpdateData?.education_orientation
            : 0),
      });
      const { education_orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.education_orientation
            ? gridUpdateData?.education_orientation
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "contract_labor_tech") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.contract_labor_tech
            ? gridUpdateData?.contract_labor_tech
            : 0),
      });
      const { contract_labor_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.contract_labor_tech
            ? gridUpdateData?.contract_labor_tech
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "contract_labor_phlebotomy") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.contract_labor_phlebotomy
            ? gridUpdateData?.contract_labor_phlebotomy
            : 0),
      });
      const { contract_labor_phlebotomy, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.contract_labor_phlebotomy
            ? gridUpdateData?.contract_labor_phlebotomy
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "histology") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.histology ? gridUpdateData?.histology : 0),
      });
      const { histology, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.histology ? gridUpdateData?.histology : 0));
      setTotalVar(sum);
    } else if (event.target.name == "client_services") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.client_services
            ? gridUpdateData?.client_services
            : 0),
      });
      const { client_services, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.client_services
            ? gridUpdateData?.client_services
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "receptionist") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.receptionist ? gridUpdateData?.receptionist : 0),
      });
      const { receptionist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.receptionist ? gridUpdateData?.receptionist : 0));
      setTotalVar(sum);
    } else if (event.target.name == "health_plan_coordinator") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.health_plan_coordinator
            ? gridUpdateData?.health_plan_coordinator
            : 0),
      });
      const { health_plan_coordinator, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.health_plan_coordinator
            ? gridUpdateData?.health_plan_coordinator
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "medical_assistant") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.medical_assistant
            ? gridUpdateData?.medical_assistant
            : 0),
      });
      const { medical_assistant, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.medical_assistant
            ? gridUpdateData?.medical_assistant
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "lead_medical_assistant") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.lead_medical_assistant
            ? gridUpdateData?.lead_medical_assistant
            : 0),
      });
      const { lead_medical_assistant, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.lead_medical_assistant
            ? gridUpdateData?.lead_medical_assistant
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "radiologist_technologist") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.radiologist_technologist
            ? gridUpdateData?.radiologist_technologist
            : 0),
      });
      const { radiologist_technologist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.radiologist_technologist
            ? gridUpdateData?.radiologist_technologist
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "athletic_trainer") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.athletic_trainer
            ? gridUpdateData?.athletic_trainer
            : 0),
      });
      const { athletic_trainer, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.athletic_trainer
            ? gridUpdateData?.athletic_trainer
            : 0));
      setTotalVar(sum);
    }
  };
  useEffect(() => {
    setGridUpdateData(gridData);
    setIsKeyEmpty(false);
    getFormData();
    setPlannedHpous(0);
    // getRnTotal();
    // console.log("hyere");
  }, [gridData]);

  useEffect(() => {
    getEditData(data_id.id);
  }, [data_id]);

  const getFormData = async () => {
    try {
      const paramData = {
        cost_center_number: cost_center_number,
      };

      const encryptData = dataEnCrypt(paramData);

      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/getshiftFormControl`,
        { payloadData: encryptData }
      );

      const data = decryptedFunction(resp?.data?.responseData);

      setFormShiftData(data?.data?.FormControl);
      setFormShiftLabel(data?.data?.formLabel);
      const convertData = (dataArray) => {
        return dataArray.reduce((acc, item) => {
          acc[item.field_name] = {
            display: item.display,
            required: item.required,
            field_label: item?.field_label,
          };
          return acc;
        }, {});
      };
      const convertedFormData = convertData(data?.data?.FormControl);
      setFormControltData(convertedFormData);
    } catch (err) {
      console.log(err);
    }
  };

  const finalPatientData = formShiftData
    ?.filter((item) => item.type === "patient_data" && item?.display !== 0)
    .sort((a, b) => a.display - b.display);

  const finalStaffingData = formShiftData
    ?.filter((item) => item.type === "staffing" && item?.display !== 0)
    .sort((a, b) => a.display - b.display);

  const finalAdditionalData = formShiftData
    ?.filter(
      (item) => item.type === "additional_data_tracking" && item?.display !== 0
    )
    .sort((a, b) => a.display - b.display);

  // Patient key Inputs
  const setpartValue = (event) => {
    if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_six, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "observation_patients") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { observation_patients, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "inpatient_boarders") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { inpatient_boarders, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "total_patients_three") {
      const optional = event.target.value;
      const sum = totalPatientvariance;
      setTotalPatientVar(totalPatientvariance);
      getDirectDataById("Grid", sum, event, optional);
    } else if (event.target.name == "pt_type1_0_20") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type1_0_20, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "pt_type2_21_28") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type2_21_28, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "pt_type3_29_68") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type3_29_68, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "of_roomed_er_patients") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { of_roomed_er_patients, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "labor_patients_recovery") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { labor_patients_recovery, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Ln Census Total = labor_patients_recovery
      const lnCensusTotal = Number(event.target.value);
      setlnCensusGrid(lnCensusTotal);
      getGridData(lnCensusTotal, nicuCensusGrid, ppCensusGrid, sum, event);
    } else if (event.target.name == "nicu_cpap") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { nicu_cpap: patientNicuCpap, ...patientRest } = patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Nicu Census Total = nicu_cpap + nicu_feeder_growers
      setNicuCensusData({
        ...nicuCensusData,
        [event.target.name]: event.target.value,
      });
      const { nicu_cpap: censusNicuCpap, ...rest } = nicuCensusData;
      const nicuCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setNicuCensusGrid(nicuCensusTotal);
      getGridData(lnCensusGrid, nicuCensusTotal, ppCensusGrid, sum, event);
    } else if (event.target.name == "nicu_feeder_growers") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { nicu_feeder_growers: patientNicuFeeder, ...patientRest } =
        patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Nicu Census Total = nicu_cpap + nicu_feeder_growers
      setNicuCensusData({
        ...nicuCensusData,
        [event.target.name]: event.target.value,
      });
      const { nicu_feeder_growers: censusNicuFeeder, ...rest } = nicuCensusData;
      const nicuCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setNicuCensusGrid(nicuCensusTotal);
      getGridData(lnCensusGrid, nicuCensusTotal, ppCensusGrid, sum, event);
    } else if (event.target.name == "post_op_pp_one_two") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { post_op_pp_one_two: patientPostOp, ...patientRest } = patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // PP Census Total = post_op_pp_one_two / 2  + stable_pp_one_three / 3
      const newValue = Math.round(Number(event.target.value) / 2);
      setPpCensusData({
        ...ppCensusData,
        [event.target.name]: newValue,
      });
      const { post_op_pp_one_two: censusPostOp, ...rest } = ppCensusData;
      const ppCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        newValue;
      setPpCensusGrid(ppCensusTotal);
      getGridData(lnCensusGrid, nicuCensusGrid, ppCensusTotal, sum, event);
    } else if (event.target.name == "stable_pp_one_three") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { stable_pp_one_three: patientStablePp, ...patientRest } =
        patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // PP Census Total = post_op_pp_one_two / 2  + stable_pp_one_three / 3
      const newValue = Math.round(Number(event.target.value) / 3);
      setPpCensusData({
        ...ppCensusData,
        [event.target.name]: newValue,
      });
      const { stable_pp_one_three: CensusStablePp, ...rest } = ppCensusData;
      const ppCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        newValue;
      setPpCensusGrid(ppCensusTotal);
      getGridData(lnCensusGrid, nicuCensusGrid, ppCensusTotal, sum, event);
    } else {
      finalPatientData.map((data) => {
        if (data?.field_name === event.target.name) {
          const sum = totalPatientvariance;
          getDirectDataById("Grid", sum, event);
        }
      });
    }
  };

  const getEditData = async (parmid) => {
    try {
      if (parmid) {
        const paramData = {
          id: parmid,
        };

        const encryptData = dataEnCrypt(paramData);
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { payloadData: encryptData }
        );
        const data = decryptedFunction(resp?.data?.responseData);
        const gridapidata = data.data.gridData;
        setTotalPatientVar(data.data.directData?.total_patients);
        setlnCensusGrid(data.data.directData?.l_and_d_census);
        setNicuCensusGrid(data.data.directData?.nicu_census);
        setPpCensusGrid(data.data.directData?.pp_census);

        let isPatientSum = [
          "two_one",
          "one_one",
          "one_two",
          "one_three",
          "one_four",
          "one_five",
          "one_six",
          "observation_patients",
          "inpatient_boarders",
          "pt_type1_0_20",
          "pt_type2_21_28",
          "pt_type3_29_68",
          "of_roomed_er_patients",
          "labor_patients_recovery",
          "nicu_cpap",
          "nicu_feeder_growers",
          "post_op_pp_one_two",
          "stable_pp_one_three",
        ];

        let patientFilter = finalPatientData
          .filter((item) => isPatientSum.includes(item.field_name))
          .reduce((obj, val) => {
            obj[val.field_name] = data?.data?.directData?.[val.field_name] || 0;
            return obj;
          }, {});

        setPatientData(patientFilter);

        // nicuCensusData sum
        let isNicuCensus = ["nicu_cpap", "nicu_feeder_growers"];

        let nicuFilter = finalPatientData
          .filter((item) => isNicuCensus.includes(item.field_name))
          .reduce((obj, val) => {
            obj[val.field_name] = data?.data?.directData?.[val.field_name] || 0;
            return obj;
          }, {});
        setNicuCensusData(nicuFilter);

        // ppCensusData sum
        let isPpCensus = ["post_op_pp_one_two", "stable_pp_one_three"];

        let ppFilter = finalPatientData
          .filter((item) => isPpCensus.includes(item.field_name))
          .reduce((obj, val) => {
            if (val.field_name === "post_op_pp_one_two") {
              obj[val.field_name] =
                Math.round(data?.data?.directData?.[val.field_name] / 2) || 0;
            } else if (val.field_name === "stable_pp_one_three") {
              obj[val.field_name] =
                Math.round(data?.data?.directData?.[val.field_name] / 3) || 0;
            }
            return obj;
          }, {});
        setPpCensusData(ppFilter);

        setFormdata(data?.data?.directData);
        setSubmitedBy(data?.data?.submitted_by);

        // Staffing - Variance Total
        let finalStaffingObj = finalStaffingData.reduce((obj, val) => {
          obj[val.field_name] =
            (data?.data?.directData?.[val.field_name] || 0) -
            (gridapidata?.[
              SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]
            ] || 0);
          return obj;
        }, {});

        setVariance(finalStaffingObj);
        // setTotalVar(Object.values(finalStaffingObj).reduce((a, b) => a + b, 0));
        setTotalVar(data.data.directData?.grid_variance);

        getRnTotal(data.data.directData); //rn total function call

        // Planned Hpous Staffing Calculation

        setPlannedHpous(data?.data?.directData?.planned_hpuos?.toFixed(3));

        const uosStaffingFilter = finalStaffingData?.reduce((obj, val) => {
          obj[val.field_name] = data?.data?.directData?.[val.field_name] || 0;
          return obj;
        }, {});
        setUosStaffingData(uosStaffingFilter);

        const uosStaffingSum = Object.values(uosStaffingFilter).reduce(
          (a, b) => a + b,
          0
        );
        setTotalUosStaffing(uosStaffingSum);

      // Planned Hpous Patient Calculation 

       const patientUos = [
         "front_desk_reg",
         "seven_ave_reg_patient",
         "ed_reg_patient",
         "procedures",
         "actual_minutes",
         "items",
       ];

        const uosPatientFilter = finalPatientData
          ?.filter((handle) => patientUos.includes(handle.field_name))
          ?.reduce((obj, val) => {
            obj[val.field_name] = data?.data?.directData?.[val.field_name] || 0;
            return obj;
          }, {});
         setUosPatientData(uosPatientFilter);

        const uosPatientSum = Object.values(uosPatientFilter).reduce(
          (a, b) => a + b,
          0
        );
        setTotalUosPatient(uosPatientSum);


      } else {
        setFormdata(init);
        setTotalPatientVar(0);
      }

      //console.log(variance, 'variance variance');
      //dispatch(setEditDepartmentDetail(data.data.directData));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
    setIsKeyEmpty(false);
    setpartValue(event);
    setvarianceValue(event);
    setRnHourValue(event);

    if (
      costCenterData?.interval === 24 &&
      !!activedatefilter?.display_planned_hpous
    ) {
      getUosPatient(event);
      getUosStaffing(event);
    }
  };

  const getDirectDataById = async (title, sum, event, optional) => {
    try {
      var dt = activedatefilter?.shift_time + ":00";

      let census;
      if (
        formControlData.total_patients_three &&
        formControlData.total_patients_three.display
      ) {
        if (optional) {
          census = +optional;
        } else {
          census = formdata.total_patients_three
            ? +formdata.total_patients_three
            : 0;
        }
      } else {
        if (sum || sum === 0) {
          census = +sum;
        } else {
          census = +totalPatientvariance;
        }
      }

      const getgridparams = {
        cost_center_number: cost_center_number,
        shift_time: dt,
        shift_date: formatDashDate(activedatefilter?.shift_date),
        census: census,
        l_and_d_census: lnCensusGrid,
        nicu_census: nicuCensusGrid,
        pp_census: ppCensusGrid,
      };

      const encryptData = dataEnCrypt(getgridparams);

      let addgriddata = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getGridData",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(addgriddata?.data?.responseData);
      const gridresult = data?.data?.gridData;
      setGridUpdateData(gridresult);

      let StaffingDataObj = finalStaffingData.reduce((obj, val) => {
        obj[val.field_name] =
          (formdata?.[val.field_name] || 0) -
          (gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
            0);
        return obj;
      }, {});

      const updateFormdata = async () => {
        return new Promise((resolve) => {
          setFormdata((prevFormdata) => {
            const updatedFormdata = {
              ...prevFormdata,
              total_patients: sum,
              [event.target.name]: event.target.value,
            };

            resolve(updatedFormdata);
            return updatedFormdata;
          });
        });
      };
      await updateFormdata();
      //
      setVariance(StaffingDataObj);
      setTotalVar(Object.values(StaffingDataObj).reduce((a, b) => a + b, 0));
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstEmptyKey = (formdata) => {
    const keysToIgnore = [
      "cost_center_number",
      "shift_date",
      "shift_time",
      "total_patients",
      "id",
      "currentShiftNumber",
    ];

    const emptyKeys = Object.keys(formdata)
      .filter((key) => !keysToIgnore.includes(key))
      .filter((key) => formControlData[key]?.required === 1)
      .filter(
        (key) =>
          formdata[key] === undefined ||
          formdata[key] === null ||
          formdata[key] === ""
      );

    if (emptyKeys.length > 1) {
      return `Please Enter Missing Data`;
    } else if (emptyKeys.length === 1) {
      return (
        "Please Enter Missing" + " " + SHIFT_FORM.VALIDATION_NAMES[emptyKeys[0]]
      );
    } else {
      return false;
    }
  };

  const submitHandle = async () => {
    let submitdata = formdata;
    let validateFormData = formControlData;
    let ischecked = [];
    submitdata["id"] = submitdata._id;
    submitdata["grid_variance"] = totalvariance;
    submitdata["rn_available_hours"] = +rnTotal;
    submitdata["wit_rn"] = witRn;
    submitdata["l_and_d_rn_variance"] = ldRnVariance;
    submitdata["nicu_variance"] = nicuVariance;
    submitdata["pp_rn_variance"] = ppRnVariance;
    submitdata["custom_rn_variance"] = customRnVariance;
    submitdata["calculated_units_of_service"] = unitServiceCal;
    submitdata["calculated_procedures_1"] = procedureOneCal;
    submitdata["calculated_procedures_2"] = procedureTwoCal;
    submitdata["calculated_items"] = itemsCal;
    submitdata["calculated_minutes"] = minutesCal;
    submitdata["in_clinic_visits"] = clinicVisitCal;
    submitdata["iol_mac_pacu_minutes"] = iolMacPacuCal;
    submitdata["of_minutes"] = ofMinutesCal;
    submitdata["calculated_total_registrations"] = totalRegCal;

    // Variance Staffing fields
    submitdata["charge_variance"] = variance?.charge;
    submitdata["cna_variance"] = variance?.cna;
    submitdata["orientation_variance"] = variance?.orientation;
    submitdata["nurses_variance"] = variance?.nurses;
    submitdata["res_brk_rn_variance"] = variance?.res_brk_rn;
    submitdata["unit_sec_variance"] = variance?.unit_sec;
    submitdata["sitter_variance"] = variance?.sitter;
    submitdata["techs_cna_grid_variance"] = variance?.techs_cna;
    submitdata["psa_si_grid_variance"] = variance?.psa_si;
    submitdata["psa_medical_grid_variance"] = variance?.psa_medical;
    submitdata["blt_grid_variance"] = variance?.blt;
    submitdata["other_grid_variance"] = variance?.other;
    submitdata["soc_worker_ft_grid_variance"] = variance?.soc_worker_ft;
    submitdata["soc_worker_pt_grid_variance"] = variance?.soc_worker_pt;
    submitdata["occ_therapist_grid_variance"] = variance?.occ_therapist;
    submitdata["pp_rn_grid_variance"] = variance?.pp_rn;
    submitdata["l_and_d_rn_grid_variance"] = variance?.l_and_d_rn;
    submitdata["nicu_peds_rn_grid_variance"] = variance?.nicu_peds_rn;
    submitdata["triage_rn_grid_variance"] = variance?.triage_rn;
    submitdata["lead_tech_grid_variance"] = variance?.lead_tech;
    submitdata["clinical_coordinator_grid_variance"] =
      variance?.clinical_coordinator;
    submitdata["front_desk_grid_variance"] = variance?.front_desk;
    submitdata["ivs_grid_variance"] = variance?.ivs;
    submitdata["ed_reg_grid_variance"] = variance?.ed_reg;
    submitdata["seven_ave_reg_grid_variance"] = variance?.seven_ave_reg;
    submitdata["rt_grid_variance"] = variance?.rt;
    submitdata["support_grid_variance"] = variance?.support;
    submitdata["scrub_tech_grid_variance"] = variance?.scrub_tech;
    submitdata["rn_preceptor_grid_variance"] = variance?.rn_preceptor;
    submitdata["rn_board_runner_grid_variance"] = variance?.rn_board_runner;
    submitdata["scrub_tech_preceptor_grid_variance"] =
      variance?.scrub_tech_preceptor;
    submitdata["scrub_tech_board_runner_grid_variance"] =
      variance?.scrub_tech_board_runner;
    submitdata["rnfa_stfa_grid_variance"] = variance?.rnfa_stfa;
    submitdata["rcu_rn_grid_variance"] = variance?.rcu_rn;
    submitdata["lab_technologist_grid_variance"] = variance?.lab_technologist;
    submitdata["phlebotomist_clinical_services_grid_variance"] =
      variance?.phlebotomist_clinical_services;
    submitdata["education_orientation_grid_variance"] =
      variance?.education_orientation;
    submitdata["contract_labor_tech_grid_variance"] =
      variance?.contract_labor_tech;
    submitdata["contract_labor_phlebotomy_grid_variance"] =
      variance?.contract_labor_phlebotomy;
    submitdata["histology_grid_variance"] = variance?.histology;
    submitdata["client_services_grid_variance"] = variance?.client_services;
    submitdata["receptionist_grid_variance"] = variance?.receptionist;
    submitdata["health_plan_coordinator_grid_variance"] =
      variance?.health_plan_coordinator;
    submitdata["medical_assistant_grid_variance"] =
      variance?.medical_assistant;
    submitdata["lead_medical_assistant_grid_variance"] =
      variance?.lead_medical_assistant;
    submitdata["radiologist_technologist_grid_variance"] =
      variance?.radiologist_technologist;
    submitdata["athletic_trainer_grid_variance"] =
      variance?.athletic_trainer;
     submitdata["grid_missing"] = gridUpdateData;

    submitdata["census_added_date_filter"] = gridUpdateData?.added_date_filter;

    // Hpous Calculation
    if (
      costCenterData?.interval === 24 &&
      !!activedatefilter?.display_planned_hpous
    ) {
      submitdata["planned_hpuos"] = plannedHpous;
    } else {
      delete submitdata["planned_hpuos"];
    }

    // census
    submitdata["l_and_d_census"] = lnCensusGrid;
    submitdata["nicu_census"] = nicuCensusGrid;
    submitdata["pp_census"] = ppCensusGrid;

    if (!formControlData?.total_patients_three?.display) {
      submitdata["total_patients_three"] = 0;
    }

    const checkval = Object.keys(submitdata).map((key) => {
      if (key != "notes") {
        if (validateFormData[key]?.required === 1) {
          if (submitdata[key] === null || submitdata[key] === "") {
            //console.log("checked");
            ischecked.push(false);
            if (key == "comment") {
              var el = document.querySelector("textarea[name=" + key + "]");
            } else {
              var el = document.querySelector("input[name=" + key + "]");
            }
            //console.log(el, "checked");
            if (el != null) {
              el.classList.add("redborder");
            }
          } else {
            if (key == "comment") {
              var el = document.querySelector("textarea[name=" + key + "]");
            } else {
              var el = document.querySelector("input[name=" + key + "]");
            }
            //console.log(el, "checked");
            if (el != null) {
              el.classList.remove("redborder");
              const getResult = getFirstEmptyKey(formdata);
              setIsKeyEmpty(getResult);
            }
            ischecked.push(true);
          }
        }
      }
    });
    const checked = ischecked.every((element) => element === true);
    if (!checked) {
      return false;
    }

    if (
      // validateFormData["total_patients"]?.display === 1 &&
      // validateFormData["total_patients"]?.required === 1 &&
      validateFormData["total_patients"]?.display !== 0 &&
      validateFormData["total_patients_two"]?.display !== 0 &&
      validateFormData["total_patients_two"]?.required === 1
    ) {
      if (+submitdata?.total_patients_two !== submitdata?.total_patients) {
        setIsKeyEmpty(
          "Please check Total Patients and Patient Acuity. They do not match."
        );
        return false;
      }
    }

    // Census cant be higher than beds
    const census = formControlData.total_patients_three.display
      ? formdata.total_patients_three
      : formdata.total_patients;
    if (validateCensus && census > validateBeds) {
      setIsKeyEmpty("Census can't be higher than beds.");
      return false;
    }

    for (let key in submitdata) {
      if (
        key !== "comment" &&
        key !== "id" &&
        (submitdata[key] === null ||
          submitdata[key] === undefined ||
          submitdata[key] === "")
      ) {
        submitdata[key] = 0;
      }
    }

    try {
      const encryptData = dataEnCrypt(submitdata);
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      socket.emit("csvUpdate", { csvAdd: false, csvUpdate: true });
      // socket.emit("csvUpdate",{csvAdd:false,csvUpdate:true})
      // setSuccessmsg(data.message);
      //   if (data.message == "Data updated succesfully") {
      reloaddata(data?.response?._id);
      callbackfunc(false, true, data.message);
      //   }
      //callbackfunc(false, true, data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const closeHandle = () => {
    reloaddata();
    callbackfunc(false);
  };

  // Rn total calculation on frontend
  const getRnTotal = async (shiftdata) => {
    const rnHourtwoOne =
      formControlData?.two_one?.display !== 0
        ? 2 * Number(shiftdata?.two_one) || 0
        : 0;
    const rnHourOneOne =
      formControlData?.one_one?.display !== 0
        ? 1 * Number(shiftdata?.one_one)
        : 0;
    const rnHourOneTwo =
      formControlData?.one_two?.display !== 0
        ? 0.5 * Number(shiftdata?.one_two)
        : 0;
    const rnHourOneThree =
      formControlData?.one_three?.display !== 0
        ? 0.33 * Number(shiftdata?.one_three)
        : 0;
    const rnHourOneFour =
      formControlData?.one_four?.display !== 0
        ? 0.25 * Number(shiftdata?.one_four)
        : 0;
    const rnHourOneFive =
      formControlData?.one_five?.display !== 0
        ? 0.2 * Number(shiftdata?.one_five)
        : 0;
    const rnHourOneSix =
      formControlData?.one_six?.display !== 0
        ? 0.1 * Number(shiftdata?.one_six) || 0
        : 0;
    const rnHourObsvPatient =
      formControlData?.observation_patients?.display !== 0
        ? 0.2 * Number(shiftdata?.observation_patients)
        : 0;
    const rnHourInPatient =
      formControlData?.inpatient_boarders?.display !== 0
        ? 0.2 * Number(shiftdata?.inpatient_boarders)
        : 0;

    const rnSumAll =
      rnHourtwoOne +
      rnHourOneOne +
      rnHourOneTwo +
      rnHourOneThree +
      rnHourOneFour +
      rnHourOneFive +
      rnHourOneSix +
      rnHourObsvPatient +
      rnHourInPatient;

    const rnHourNurse =
      formControlData?.nurses?.display !== 0 ? Number(shiftdata?.nurses) : 0;
    const totalRnSum = rnHourNurse - rnSumAll;

    const updatedRnAvailableData = {
      ...rnAvailableData,
      two_one: rnHourtwoOne,
      one_one: rnHourOneOne,
      one_two: rnHourOneTwo,
      one_three: rnHourOneThree,
      one_four: rnHourOneFour,
      one_five: rnHourOneFive,
      one_six: rnHourOneSix,
      observation_patients: rnHourObsvPatient,
      inpatient_boarders: rnHourInPatient,
    };
    setRnAvailableData(updatedRnAvailableData);
    setRnTotal(totalRnSum?.toFixed(2));
  };

  // Rn Total Hours Input Formula
  const setRnHourValue = (event) => {
    if (event.target.name == "two_one") {
      const rnHourSum = 2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { two_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_one") {
      const rnHourSum = 1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
      //setFormdata(...formdata, one_one);
    } else if (event.target.name == "one_two") {
      const rnHourSum = 0.5 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_two, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_three") {
      const rnHourSum = 0.33 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_three, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_four") {
      const rnHourSum = 0.25 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_four, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_five") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_five, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_six") {
      const rnHourSum = 0.1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_six, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "nurses") {
      const { ...rest } = rnAvailableData;
      const sum =
        Number(event.target.value) -
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0);
      const rnSum = sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "observation_patients") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { observation_patients, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "inpatient_boarders") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { inpatient_boarders, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    }
  };

  // PLANNED HPOUS CALCULATION
  useEffect(() => {
    costCenterData?.interval === 24 &&
      !!activedatefilter?.display_planned_hpous &&
      calculateUosHpous(
        totalUosPatient,
        totalUosStaffing,
        ofMinutesCal,
        clinicVisitCal,
        procedureOneCal,
        procedureTwoCal,
        iolMacPacuCal
      );
  }, [
    ofMinutesCal,
    clinicVisitCal,
    procedureOneCal,
    procedureTwoCal,
    iolMacPacuCal,
  ]);

  // PLANNED HPOUS CALCULATION
  const getUosPatient = (event) => {
    const { name, value } = event.target;
    const validNames = [
      "front_desk_reg",
      "seven_ave_reg_patient",
      "ed_reg_patient",
      "procedures",
      "calculated_procedures_1",
      "calculated_procedures_2",
      "actual_minutes",
      "of_minutes",
      "iol_mac_pacu_minutes",
      "items",
      "in_clinic_visits",
    ];

    if (validNames.includes(name)) {
      const updatedData = {
        ...uosPatientData,
        [name]: value,
      };

      const sum = Object.values(updatedData).reduce(
        (a, b) => Number(a) + Number(b),
        0
      );

      setUosPatientData(updatedData);
      setTotalUosPatient(sum);
      calculateUosHpous(sum, totalUosStaffing);
    }
  };

  // PLANNED HPOUS CALCULATION
  const getUosStaffing = (event) => {
    const { name, value } = event.target;

    finalStaffingData.map((data) => {
      if (data?.field_name === name) {
        const updatedData = {
          ...uosStaffingData,
          [name]: value,
        };

        const sum = Object.values(updatedData).reduce(
          (a, b) => Number(a) + Number(b),
          0
        );

        setUosStaffingData(updatedData);
        setTotalUosStaffing(sum);
        calculateUosHpous(totalUosPatient, sum);
      }
    });
  };

  // PLANNED HPOUS CALCULATION
  const calculateUosHpous = (
    uosPatient,
    uosStaffing,
    ofMinutes,
    clinicVisit,
    procedureOne,
    procedureTwo,
    iolMacPacu
  ) => {
   const ofMinutesVal =
     formControlData?.of_minutes && formControlData?.of_minutes?.display !== 0
       ? ofMinutes
         ? Number(ofMinutes)
         : Number(ofMinutesCal)
       : Number(0);
   const clinicVisitVal =
     formControlData?.in_clinic_visits &&
     formControlData?.in_clinic_visits?.display !== 0
       ? clinicVisit
         ? Number(clinicVisit)
         : Number(clinicVisitCal)
       : Number(0);
   const procedureOneVal =
     formControlData?.calculated_procedures_1 &&
     formControlData?.calculated_procedures_1?.display !== 0
       ? procedureOne
         ? Number(procedureOne)
         : Number(procedureOneCal)
       : Number(0);
   const procedureTwoVal =
     formControlData?.calculated_procedures_2 &&
     formControlData?.calculated_procedures_2?.display !== 0
       ? procedureTwo
         ? Number(procedureTwo)
         : Number(procedureTwoCal)
       : Number(0);
   const iolMacPacuVal =
     formControlData?.iol_mac_pacu_minutes &&
     formControlData?.iol_mac_pacu_minutes?.display !== 0
       ? iolMacPacu
         ? Number(iolMacPacu)
         : Number(iolMacPacuCal)
       : Number(0);


    const uos24 =
      Number(uosPatient) +
      ofMinutesVal +
      clinicVisitVal +
      procedureOneVal +
      procedureTwoVal +
      iolMacPacuVal;

    const worked_hour = Number(uosStaffing);

    const planned_hpous = worked_hour / uos24;
      if (isFinite(planned_hpous)) {
        const roundedValue = +planned_hpous.toFixed(3);
        setPlannedHpous(roundedValue);
      } else {
        setPlannedHpous(0);
      } 
  };

  // RN Variance and WIT/RN
  useEffect(() => {
    const witValue = formdata?.wit ? formdata?.wit : 0;
    const rnTotalValue = formdata?.nurses ? formdata?.nurses : 0;
    const rnAcuityNurse = acuityNurse ? acuityNurse : 0;

    const rnVarianceCalculation =
      rnAcuityNurse !== 0
        ? (rnAcuityNurse * rnTotalValue - witValue) / rnAcuityNurse
        : 0;
    setRnVariance(
      isNaN(rnVarianceCalculation) ? 0 : rnVarianceCalculation?.toFixed(2)
    );

    if (isNaN(witValue) || isNaN(rnTotalValue) || rnTotalValue === 0) {
      setWitRn(0);
    } else {
      const witRnDivider = witValue / rnTotalValue;
      if (isFinite(witRnDivider)) {
        const roundedValue = +witRnDivider.toFixed(2);
        setWitRn(roundedValue);
      } else {
        setWitRn(0);
      }
    }
  }, [formdata?.wit, formdata?.nurses]);

  // L&D RN Variance
  useEffect(() => {
    const labourPatientValue =
      formControlData?.labor_patients_recovery?.display !== 0 &&
      formdata?.labor_patients_recovery
        ? formdata?.labor_patients_recovery
        : 0;
    const ldRnValue =
      formControlData?.l_and_d_rn?.display !== 0 && formdata?.l_and_d_rn
        ? formdata?.l_and_d_rn
        : 0;

    const ldRnMulti = 1 * labourPatientValue;
    const ldRnTotalVariance = ldRnValue - ldRnMulti;
    if (ldRnTotalVariance !== 0) {
      setLdRnVariance(ldRnTotalVariance?.toFixed(0));
    } else {
      setLdRnVariance(0);
    }
  }, [formdata?.labor_patients_recovery, formdata?.l_and_d_rn]);

  // NICU Variance
  useEffect(() => {
    const nicuPedsValue =
      formControlData?.nicu_peds_rn?.display !== 0 && formdata?.nicu_peds_rn
        ? formdata?.nicu_peds_rn
        : 0;
    const nicuFeederGrowerValue =
      formControlData?.nicu_feeder_growers?.display !== 0 &&
      formdata?.nicu_feeder_growers
        ? formdata?.nicu_feeder_growers
        : 0;
    const nicuCpapValue =
      formControlData?.nicu_cpap?.display !== 0 && formdata?.nicu_cpap
        ? formdata?.nicu_cpap
        : 0;
    const multiplierOneTwo = 0.5;
    const multiplierOneThree = 0.333;
    const nicuRnVariance =
      nicuPedsValue -
      (nicuFeederGrowerValue * multiplierOneThree +
        nicuCpapValue * multiplierOneTwo);
    if (nicuRnVariance !== 0) {
      setNicuVariance(nicuRnVariance?.toFixed(3));
    } else {
      setNicuVariance(0);
    }
  }, [
    formdata?.nicu_peds_rn,
    formdata?.nicu_feeder_growers,
    formdata?.nicu_cpap,
  ]);

  // PP RN Variance
  useEffect(() => {
    const ppRnValue =
      formControlData?.pp_rn?.display !== 0 && formdata?.pp_rn
        ? formdata?.pp_rn
        : 0;
    const postOpValue =
      formControlData?.post_op_pp_one_two?.display !== 0 &&
      formdata?.post_op_pp_one_two
        ? formdata?.post_op_pp_one_two
        : 0;
    const stableppValue =
      formControlData?.stable_pp_one_three?.display !== 0 &&
      formdata?.stable_pp_one_three
        ? formdata?.stable_pp_one_three
        : 0;
    const multiplierOneTwo = 0.5;
    const multiplierOneThree = 0.333;
    const nicuRnVariance =
      ppRnValue -
      (postOpValue * multiplierOneTwo + stableppValue * multiplierOneThree);
    if (nicuRnVariance !== 0) {
      setppRnVariance(nicuRnVariance?.toFixed(3));
    } else {
      setppRnVariance(0);
    }
  }, [
    formdata?.pp_rn,
    formdata?.post_op_pp_one_two,
    formdata?.stable_pp_one_three,
  ]);

  // Custom Rn Variance
  useEffect(() => {
    const nursesValue =
      formControlData?.nurses?.display !== 0 && formdata?.nurses
        ? formdata?.nurses
        : 0;
    const standardUnitValue = formContent?.standard_unit_ratio || 0;
    const censusValue =
      formControlData?.total_patients_three?.display !== 0
        ? formdata?.total_patients_three
        : formdata?.total_patients;
    const unitDivider =
      standardUnitValue !== 0 ? censusValue / standardUnitValue : 0;
    const unitTotal = isNaN(unitDivider) ? 0 : unitDivider;
    const customRnValue = nursesValue - unitTotal;
    if (customRnValue !== 0) {
      setCustomRnVariance(customRnValue?.toFixed(1));
    } else {
      setCustomRnVariance(0);
    }
  }, [
    formdata?.nurses,
    formdata?.total_patients,
    formdata?.total_patients_three,
  ]);

  // Calculated Unit Service
  useEffect(() => {
    const ptScheduledValue =
      formControlData?.pts_scheduled?.display !== 0 && formdata?.pts_scheduled
        ? formdata?.pts_scheduled
        : 0;
    setUnitServiceCal(ptScheduledValue);
  }, [formdata?.pts_scheduled]);

  // Calculated Procedures 1
  useEffect(() => {
    const EchoValue =
      formControlData?.echo?.display !== 0 && formdata?.echo
        ? formdata?.echo
        : 0;
    const DobutamineValue =
      formControlData?.dobutamine_stress_echo?.display !== 0 &&
      formdata?.dobutamine_stress_echo
        ? formdata?.dobutamine_stress_echo
        : 0;
    const StressValue =
      formControlData?.stress_echo?.display !== 0 && formdata?.stress_echo
        ? formdata?.stress_echo
        : 0;
    const TransesoValue =
      formControlData?.transesopageal_echo?.display !== 0 &&
      formdata?.transesopageal_echo
        ? formdata?.transesopageal_echo
        : 0;
    const ofCardiacValue =
      formControlData?.of_cardiac_procedures?.display !== 0 &&
      formdata?.of_cardiac_procedures
        ? formdata?.of_cardiac_procedures
        : 0;
    const iolPainValue =
      formControlData?.iol_pain_local_cases?.display !== 0 &&
      formdata?.iol_pain_local_cases
        ? formdata?.iol_pain_local_cases
        : 0;
    const painMangValue =
      formControlData?.pain_management?.display !== 0 &&
      formdata?.pain_management
        ? formdata?.pain_management
        : 0;
    const totalProcedure1 =
      Number(EchoValue) +
      Number(DobutamineValue) +
      Number(StressValue) +
      Number(TransesoValue) +
      Number(ofCardiacValue) +
      Number(iolPainValue) +
      Number(painMangValue);

    if (totalProcedure1 !== 0) {
      setProcedureOneCal(totalProcedure1?.toFixed(2));
    } else {
      setProcedureOneCal(0);
    }
  }, [
    formdata?.echo,
    formdata?.dobutamine_stress_echo,
    formdata?.stress_echo,
    formdata?.transesopageal_echo,
    formdata?.of_cardiac_procedures,
    formdata?.iol_pain_local_cases,
    formdata?.pain_management,
  ]);

  // Calculated Procedures 2
  useEffect(() => {
    const giValue =
      formControlData?.gi?.display !== 0 && formdata?.gi ? formdata?.gi : 0;
    const ErcpValue =
      formControlData?.ercp?.display !== 0 && formdata?.ercp
        ? formdata?.ercp
        : 0;
    const BronchoValue =
      formControlData?.bronchoscopy?.display !== 0 && formdata?.bronchoscopy
        ? formdata?.bronchoscopy
        : 0;
    const totalProcedure2 =
      Number(giValue) + Number(ErcpValue) + Number(BronchoValue);

    if (totalProcedure2 !== 0) {
      setProcedureTwoCal(totalProcedure2?.toFixed(2));
    } else {
      setProcedureTwoCal(0);
    }
  }, [formdata?.gi, formdata?.ercp, formdata?.bronchoscopy]);

  // Calculated Items
  useEffect(() => {
    const itemValue =
      formControlData?.items?.display !== 0 && formdata?.items
        ? formdata?.items
        : 0;
    setItemsCal(Number(itemValue));
  }, [formdata?.items]);

  // Calculated  Minutes
  useEffect(() => {
    const actualMinuteValue =
      formControlData?.actual_minutes?.display !== 0 && formdata?.actual_minutes
        ? formdata?.actual_minutes
        : 0;
    const totalMinutes = Number(actualMinuteValue) * 0.83;
    if (totalMinutes !== 0) {
      setMinutesCal(totalMinutes?.toFixed(2));
    } else {
      setMinutesCal(0);
    }
  }, [formdata?.actual_minutes]);

  // Calculated  Total Registrations
  useEffect(() => {
    const frontDeskReg =
      formControlData?.front_desk_reg?.display !== 0 && formdata?.front_desk_reg
        ? formdata?.front_desk_reg
        : 0;
    const sevenAveReg =
      formControlData?.seven_ave_reg_patient?.display !== 0 &&
      formdata?.seven_ave_reg_patient
        ? formdata?.seven_ave_reg_patient
        : 0;
    const edReg =
      formControlData?.ed_reg_patient?.display !== 0 && formdata?.ed_reg_patient
        ? formdata?.ed_reg_patient
        : 0;
    const totalRegistration =
      Number(frontDeskReg) + Number(sevenAveReg) + Number(edReg);

    if (totalRegistration !== 0) {
      setTotalRegCal(totalRegistration?.toFixed(2));
    } else {
      setTotalRegCal(0);
    }
  }, [
    formdata?.front_desk_reg,
    formdata?.seven_ave_reg_patient,
    formdata?.ed_reg_patient,
  ]);

  // Calculated  In-Clinic Visits
  useEffect(() => {
    const levelVisitOne =
      formControlData?.level_1_visits?.display !== 0 && formdata?.level_1_visits
        ? formdata?.level_1_visits
        : 0;
    const levelVisitTwo =
      formControlData?.level_2_visits?.display !== 0 && formdata?.level_2_visits
        ? formdata?.level_2_visits
        : 0;
    const levelVisitThree =
      formControlData?.level_3_visits?.display !== 0 && formdata?.level_3_visits
        ? formdata?.level_3_visits
        : 0;
    const levelVisitFour =
      formControlData?.level_4_visits?.display !== 0 && formdata?.level_4_visits
        ? formdata?.level_4_visits
        : 0;

    const levelVisitFive =
      formControlData?.level_5_visits?.display !== 0 && formdata?.level_5_visits
        ? formdata?.level_5_visits
        : 0;

    const totalClinicVisit =
      Number(levelVisitOne) +
      Number(levelVisitTwo) +
      Number(levelVisitThree) +
      Number(levelVisitFour) +
      Number(levelVisitFive);

    if (totalClinicVisit !== 0) {
      setClinicVisitCal(totalClinicVisit?.toFixed(2));
    } else {
      setClinicVisitCal(0);
    }
  }, [
    formdata?.level_1_visits,
    formdata?.level_2_visits,
    formdata?.level_3_visits,
    formdata?.level_4_visits,
    formdata?.level_5_visits,
  ]);

  // Calculated  IOL, Mac, Pacu Minutes
  useEffect(() => {
    const iolPlainCases =
      formControlData?.iol_pain_local_cases?.display !== 0 &&
      formdata?.iol_pain_local_cases
        ? formdata?.iol_pain_local_cases
        : 0;
    const MaCases =
      formControlData?.mac_cases?.display !== 0 && formdata?.mac_cases
        ? formdata?.mac_cases
        : 0;
    const pacuCases =
      formControlData?.pacu_cases?.display !== 0 && formdata?.pacu_cases
        ? formdata?.pacu_cases
        : 0;

    const totalIolMacu =
      Number(iolPlainCases) * 30 +
      Number(MaCases) * 60 +
      Number(pacuCases) * 45;

    if (totalIolMacu !== 0) {
      setIolMacPacuCal(totalIolMacu?.toFixed(2));
    } else {
      setIolMacPacuCal(0);
    }
  }, [
    formdata?.iol_pain_local_cases,
    formdata?.mac_cases,
    formdata?.pacu_cases,
  ]);

  // Calculated  Of Minutes
  useEffect(() => {
    const ofCasesValue =
      formControlData?.of_cases?.display !== 0 && formdata?.of_cases
        ? formdata?.of_cases
        : 0;
    const totalOfMinutes = 143 * Number(ofCasesValue);
    if (totalOfMinutes !== 0) {
      setOfMinutesCal(totalOfMinutes?.toFixed(2));
    } else {
      setOfMinutesCal(0);
    }
  }, [formdata?.of_cases]);

  // // L&D Census that drives L&D RN grid
  // useEffect(() => {
  //   const labourPatientValue =
  //     formControlData?.labor_patients_recovery?.display !== 0 &&
  //     formdata?.labor_patients_recovery
  //       ? formdata?.labor_patients_recovery
  //       : 0;

  //   const lnTotal = Number(labourPatientValue);
  //   setlnCensusGrid(lnTotal);
  //   getGridData(lnTotal, nicuCensusGrid, ppCensusGrid);
  // }, [formdata?.labor_patients_recovery]);

  // // NICU census that drives NICU Peds / RN grid
  // useEffect(() => {
  //     const nicuCpapValue =
  //       formControlData?.nicu_cpap?.display !== 0 && formdata?.nicu_cpap
  //         ? formdata?.nicu_cpap
  //         : 0;

  //     const nicuFeederValue =
  //       formControlData?.nicu_feeder_growers?.display !== 0 &&
  //       formdata?.nicu_feeder_growers
  //         ? formdata?.nicu_feeder_growers
  //         : 0;

  //     const nicuTotal = Number(nicuCpapValue) + Number(nicuFeederValue);
  //     setNicuCensusGrid(nicuTotal);
  //     getGridData(lnCensusGrid, nicuTotal, ppCensusGrid);
  // }, [formdata?.nicu_cpap, formdata?.nicu_feeder_growers]);

  // // PP Census that drives PP RN grid
  // useEffect(() => {
  //     const postOpValue =
  //       formControlData?.post_op_pp_one_two?.display !== 0 &&
  //       formdata?.post_op_pp_one_two
  //         ? Math.round(formdata?.post_op_pp_one_two / 2)
  //         : 0;

  //     const stableppValue =
  //       formControlData?.stable_pp_one_three?.display !== 0 &&
  //       formdata?.stable_pp_one_three
  //         ? Math.round(formdata?.stable_pp_one_three / 3)
  //         : 0;

  //     const totalPpCensus = Number(postOpValue) + Number(stableppValue);
  //     setPpCensusGrid(totalPpCensus);
  //     getGridData(lnCensusGrid, nicuCensusGrid, totalPpCensus);
  // }, [formdata?.post_op_pp_one_two, formdata?.stable_pp_one_three]);

  const getGridData = async (
    lnCensus,
    nicuCensus,
    ppCensus,
    patientSum,
    event
  ) => {
    try {
      let census;
      if (
        formControlData.total_patients_three &&
        formControlData.total_patients_three.display
      ) {
        census = formdata.total_patients_three
          ? +formdata.total_patients_three
          : 0;
      } else {
        census = patientSum ? +patientSum : 0;
      }

      const getgridparams = {
        cost_center_number: cost_center_number,
        shift_time: activedatefilter.shift_time + ":00",
        shift_date: formatDashDate(activedatefilter?.shift_date),
        census: census,
        l_and_d_census: lnCensus,
        nicu_census: nicuCensus,
        pp_census: ppCensus,
      };

      const encryptData = dataEnCrypt(getgridparams);

      let addgriddata = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getGridData",
        { payloadData: encryptData }
      );

      const data = decryptedFunction(addgriddata?.data?.responseData);
      const gridresult = data?.data?.gridData;
      setGridUpdateData(gridresult);

      let StaffingGridData = finalStaffingData.reduce((obj, val) => {
        let fieldValue =
          (formdata?.[val.field_name] || formdata?.[val.field_name] === 0) &&
          (gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
            gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ===
              0)
            ? +formdata?.[val.field_name] -
              gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]]
            : 0;

        obj[val.field_name] = fieldValue;
        return obj;
      }, {});

      const updateFormdata = async () => {
        return new Promise((resolve) => {
          setFormdata((prevFormdata) => {
            const updatedFormdata = {
              ...prevFormdata,
              total_patients: patientSum,
              [event.target.name]: event.target.value,
            };

            resolve(updatedFormdata);
            return updatedFormdata;
          });
        });
      };
      await updateFormdata();
      setVariance(StaffingGridData);
      setTotalVar(Object.values(StaffingGridData).reduce((a, b) => a + b, 0));
    } catch (error) {
      console.log(error);
    }
  };

  // HANDLE DID BLT TOGGLE
  const handleBltChecked = () => {
    setBltChecked((prevState) => !prevState);
    setFormdata((prevData) => ({
      ...prevData,
      did_blt_cover_obs: !bltChecked ? 1 : 0,
    }));
  };

  const varianceTrackingState = {
    rn_available_hours: rnTotal,
    wit_rn: witRn,
    rn_variance: rnVariance,
    l_and_d_rn_variance: ldRnVariance,
    nicu_variance: nicuVariance,
    pp_rn_variance: ppRnVariance,
    custom_rn_variance: customRnVariance,
    calculated_units_of_service: unitServiceCal,
    calculated_procedures_1: procedureOneCal,
    calculated_procedures_2: procedureTwoCal,
    calculated_items: itemsCal,
    calculated_minutes: minutesCal,
    calculated_total_registrations: totalRegCal,
    in_clinic_visits: clinicVisitCal,
    iol_mac_pacu_minutes: iolMacPacuCal,
    of_minutes: ofMinutesCal,
    populated_minutes_1: formdata?.populated_minutes_1,
    populated_minutes_2: formdata?.populated_minutes_2,
    populated_minutes_3: formdata?.populated_minutes_3,
  };

  return (
    <Modal
      show={isShow}
      onHide={() => callbackfunc(false, false, successMsg)}
      transparent={true}
      centered
    >
      <div className=" modal-dialog-centered modal-lg">
        <div className="modal-content pb-4">
          <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
            <h5 class="modal-title fw-400 text-uppercase">
              Editing - {cost_center_name}&nbsp;-&nbsp;
              <span class="">
                {activedatefilter?.shift_date}{" "}
                {costCenterData?.interval !== 24 &&
                  activedatefilter?.shift_time}{" "}
                INTERVAL
              </span>
            </h5>
            <button
              type="button"
              onClick={closeHandle}
              className="btn-close ms-5 position-absolute right-20 bg-transparent"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body inputnumbericonhide py-0 para-5">
            <div className="row">
              <div className="col-md-3 border-end w1">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.patient_data_label
                      ? formShiftLabel?.patient_data_label
                      : "Patient Data"}
                  </h6>
                </div>
                <div className="min-height-440">
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        {userType === 0 && <th>Previous</th>}
                        {/* <th> Previous <br /> {contentone.shift}</th>  {previousShiftData?.shift}*/}
                        <th>
                          {costCenterData?.interval !== 24 &&
                            activedatefilter?.shift_time}
                        </th>
                      </tr>
                      {finalPatientData?.map((val, ind) => {
                        const varianceRowFields = [
                          "rn_available_hours",
                          "wit_rn",
                          "rn_variance",
                          "l_and_d_rn_variance",
                          "nicu_variance",
                          "pp_rn_variance",
                          "custom_rn_variance",
                          "calculated_units_of_service",
                          "calculated_procedures_1",
                          "calculated_procedures_2",
                          "calculated_items",
                          "calculated_minutes",
                          "calculated_total_registrations",
                          "in_clinic_visits",
                          "iol_mac_pacu_minutes",
                          "of_minutes",
                          "populated_minutes_1",
                          "populated_minutes_2",
                          "populated_minutes_3",
                        ];
                        const varianceRenderRow = varianceRowFields.includes(
                          val.field_name
                        )
                          ? val.field_name
                          : null;

                        return (
                          <tr key={ind}>
                            <>
                              {val.field_name === "total_patients" ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td className="img last-i">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>
                                  {userType === 0 && <td></td>}
                                  <td>
                                    <hr className="totalline my-1" />
                                    <div
                                      type="button"
                                      className={`tooltip left-tool position-relative ${
                                        totalPatientvariance < 0
                                          ? "textdanger"
                                          : ""
                                      }`}
                                    >
                                      {formatDecimalNumber(
                                        totalPatientvariance
                                      )}
                                      <span className="tooltiptext">Total</span>
                                    </div>
                                  </td>
                                </>
                              ) : val.field_name === varianceRenderRow ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td className="img">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>
                                  {userType === 0 && <td></td>}
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      name={val?.field_name}
                                      value={
                                        varianceTrackingState[val?.field_name]
                                      }
                                      type="number"
                                      onChange={handleChange}
                                      disabled
                                      aria-label="input example"
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td className="img">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>
                                  {userType === 0 && (
                                    <td>
                                      <input
                                        className="form-control"
                                        name={val?.field_name}
                                        value={
                                          previousShiftData?.[val?.field_name]
                                        }
                                        type="text"
                                        disabled
                                        aria-label="input example"
                                        readOnly
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      name={val?.field_name}
                                      value={
                                        formdata?.[val?.field_name] !== null
                                          ? formdata[val?.field_name]
                                          : ""
                                      }
                                      type="number"
                                      onChange={handleChange}
                                      aria-label="input example"
                                    />
                                  </td>
                                </>
                              )}
                            </>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {costCenterData?.interval === 24 &&
                  !!activedatefilter?.display_planned_hpous && (
                    <div>
                      <table className="w-100 mb-2 m-g-2">
                        <tbody>
                          <tr>
                            <>
                              <td className="t w-50">Planned HPUOS</td>
                              <td className="img"></td>
                              <td className="w-25"></td>
                              <td className="tdx">
                                <input
                                  className="form-control"
                                  // name={val?.field_name}
                                  value={plannedHpous}
                                  type="number"
                                  // onChange={handleChange}
                                  disabled
                                  aria-label=" input example"
                                />
                              </td>
                            </>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                <div class="col mt-4">
                  <div class="f-14 text-start">
                    Last Updated By: {submitedBy}
                  </div>
                </div>
              </div>
              <div className="col-md-5 w2">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.staffing_data_label
                      ? formShiftLabel?.staffing_data_label
                      : "Staffing"}
                  </h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      {userType === 0 && <th>Previous</th>}
                      <th>
                        {costCenterData?.interval !== 24 &&
                          activedatefilter?.shift_time}
                      </th>
                      <th>Grid</th>
                      <th>Variance</th>
                    </tr>
                    {finalStaffingData?.map((val, ind) => {
                      return (
                        <tr key={ind}>
                          <>
                            <td className="t">{val?.field_label}</td>
                            <td class="img">
                              <img
                                src={
                                  themeMode.theme === "DARK"
                                    ? SHIFT_FORM.STAFFING_ICON_DARK?.[
                                        val?.field_name
                                      ]
                                    : SHIFT_FORM.STAFFING_ICON_LIGHT?.[
                                        val?.field_name
                                      ]
                                }
                                alt=""
                              />
                            </td>
                            {userType === 0 && (
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={previousShiftData?.[val?.field_name]}
                                  disabled
                                  aria-label=" input example"
                                  readonly
                                />
                              </td>
                            )}
                            <td>
                              <input
                                className={`form-control ${
                                  formdata?.[val?.field_name] === "" &&
                                  val?.required === 1
                                    ? "redborder"
                                    : ""
                                }`}
                                name={val?.field_name}
                                value={
                                  formdata?.[val?.field_name] != null
                                    ? formdata?.[val?.field_name]
                                    : ""
                                }
                                type="number"
                                onChange={handleChange}
                                aria-label=" input example"
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={
                                  gridUpdateData?.[
                                    SHIFT_FORM?.STAFFING_GRID_VALUE?.[
                                      val?.field_name
                                    ]
                                  ] || 0
                                }
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                            <td>
                              <div
                                type="button"
                                class="tooltip left-tool position-relative"
                              >
                                <input
                                  className={`form-control ${
                                    variance?.[val?.field_name] > 0
                                      ? "red-text"
                                      : ""
                                  }`}
                                  type="text"
                                  value={formatDecimalNumber(
                                    variance?.[val?.field_name]
                                  )}
                                  disabled
                                  aria-label="input example"
                                  readonly
                                />
                                <span class="tooltiptext">
                                  {val?.field_label}
                                </span>
                              </div>
                            </td>
                          </>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {userType === 0 && <td></td>}
                      {!isEmpty(finalStaffingData) && (
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalvariance > 0 ? "textdanger" : ""
                            }`}
                          >
                            {formatDecimalNumber(totalvariance)}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4 border-start">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.additional_data_label
                      ? formShiftLabel?.additional_data_label
                      : "ADDITIONAL DATA TRACKING"}
                  </h6>
                </div>
                <div className="height-417">
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        {userType === 0 && <th>Previous</th>}
                        <th>
                          {costCenterData?.interval !== 24 &&
                            activedatefilter?.shift_time}
                        </th>
                      </tr>
                      {finalAdditionalData?.map((val, ind) => {
                        if (
                          val.field_name === "comment" ||
                          val.field_name === "submitted_by_text"
                        ) {
                          return null;
                        }
                        return (
                          <tr key={ind}>
                            <>
                              {val.field_name === "did_blt_cover_obs" ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td class="img">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.ADDITIONAL_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.ADDITIONAL_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>

                                  {userType === 0 && (
                                    <td>
                                      <div class="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          class="checkbox"
                                          checked={
                                            previousShiftData?.did_blt_cover_obs
                                          }
                                          disabled
                                        />
                                        <div class="knobs"></div>
                                        <div class="layer"></div>
                                      </div>
                                    </td>
                                  )}
                                  <td>
                                    <div class="button r" id="button-1">
                                      <input
                                        type="checkbox"
                                        class="checkbox"
                                        onChange={handleBltChecked}
                                        checked={formdata?.did_blt_cover_obs}
                                      />
                                      <div class="knobs"></div>
                                      <div class="layer"></div>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td class="img">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.ADDITIONAL_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.ADDITIONAL_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>
                                  {userType === 0 && (
                                    <td>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={
                                          previousShiftData?.[val?.field_name]
                                        }
                                        disabled
                                        aria-label=" input example"
                                        readonly
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      type="number"
                                      onChange={handleChange}
                                      name={val?.field_name}
                                      value={
                                        formdata?.[val?.field_name] !== null
                                          ? formdata[val?.field_name]
                                          : ""
                                      }
                                      aria-label=" input example"
                                    />
                                  </td>
                                </>
                              )}
                            </>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="row mb-3">
                    {formControlData?.submitted_by_text &&
                      formControlData?.submitted_by_text?.display !== 0 && (
                        <div className="col-md-12">
                          <div class="col">
                            <div className="m-f14">
                              {formControlData?.submitted_by_text?.field_label}
                            </div>
                            <div className="mt-1 w-100-input">
                              <input
                                className={`w-100 ${
                                  formdata?.submitted_by_text === "" &&
                                  formControlData?.submitted_by_text
                                    ?.required === 1
                                    ? "redborder"
                                    : ""
                                }`}
                                onChange={handleChange}
                                name="submitted_by_text"
                                value={formdata?.submitted_by_text}
                                placeholder="Type Here..."
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    {formControlData?.comment &&
                      formControlData?.comment?.display !== 0 && (
                        <div className="col-md-12">
                          <div class="col">
                            <div className="m-f14">
                              {formControlData?.comment?.field_label}
                            </div>
                            <div className="mt-1">
                              <textarea
                                className="form-control full"
                                onChange={handleChange}
                                name="comment"
                                value={formdata?.comment}
                                placeholder="Type Here..."
                                style={{ height: "70px" }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="button"
                      onClick={closeHandle}
                      className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={submitHandle}
                      className="btn btn-success text-capitalize  f-12 fw-400 w-100 finsh"
                    >
                      {" "}
                      Update
                    </button>
                  </div>
                </div>
                {isKeyEmpty && (
                  <div className="real-validation m-f14 mt-2">{isKeyEmpty}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateModal;
